module services {
    export module consignment {
        export class consignmentService implements interfaces.consignment.IConsignmentService {
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            getConsignmentList(): ng.resource.IResourceClass<interfaces.consignment.IConsignmentDisplay> {
                return this.$resource<interfaces.consignment.IConsignmentDisplay>(this.ENV.DSP_URL + "Consignment/GetConsignments", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber', 
                    isInbound: '@isInbound'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

            getConsignmentsExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Consignment/GetConsignmentsExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));
                url += '&IsInbound=' + params.isInbound;

                if (params.invoiceNumber)
                    url += '&invoiceNumber=' + params.invoiceNumber;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            GetConsignmentsPRListExcel(conId: number, showOnlyProhibited: boolean): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Consignment/GetConsignmentsPRListExcel?conId=' + conId + '&showOnlyProhibited=' + showOnlyProhibited;
                    
                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            //Called by "Create New" button
            create(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/Create", {
                });
            }

            createFromExisting(ownerEntityId: number, typeId: number, shipperId: number, consigneeId: number, routeCodeId: number, customNumber: string ,consignmentId:number,counterId: number, isInbound?: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/Create", {
                    OwnerEntityId: ownerEntityId,
                    typeId: typeId,
                    shipperId: shipperId,
                    consigneeId: consigneeId,
                    consignmentId: consignmentId,
                    routeCodeId: routeCodeId,
                    counterId: counterId,

                    customNumber: customNumber,
     
                    isInbound: isInbound,            
                });
            }

            //Called by Submit button in updateView
            update(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler>
            {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/Update", {
                });
            }

            //Called by "Create From Existing" button.
            createExisting(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/CreateExisting", {
                    OwnerEntityId: '@OwnerEntityId',
                    TypeId: '@TypeId',
                    SupplierBranchId: '@SupplierBranchId',
                    POCounterId: '@POCounterId',
                    CustomNumber: '@CustomNumber',
                    poId: '@poId'
                });
            }

            //Called by "Create From Sales Order" button.
            createSalesOrder(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/CreateSalesOrder", {
                    OwnerEntityId: '@OwnerEntityId',
                    TypeId: '@TypeId',
                    SupplierBranchId: '@SupplierBranchId',
                    POCounterId: '@POCounterId',
                    CustomNumber: '@CustomNumber',
                    poId: '@poId'
                });
            }

            //Called by "Next" button from Creating Sales Order.
            createSalesOrderNext(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/CreateSalesOrderNext", {
                    OwnerEntityId: '@OwnerEntityId',
                    TypeId: '@TypeId',
                    SupplierBranchId: '@SupplierBranchId',
                    POCounterId: '@POCounterId',
                    CustomNumber: '@CustomNumber',
                    poId: '@poId'
                });
            }

            //Get all Consignments
            GetConsignmentsDropdown(ownerEntityId: number, isInbound:boolean, searchText: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Consignment/GetConsignmentsDropdown", {
                    ownerEntityId: ownerEntityId,
                    isInbound: isInbound,
                    searchText: searchText
                });
            }

            //Get a list of countries for Loading
            getCountryLoadingList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Country/GetDropdownList", {});
            }

            //Get a Consignment
            GetConsignment(consignmentID: number): ng.resource.IResourceClass<interfaces.consignment.IConsignment>
            {
                return this.$resource<interfaces.consignment.IConsignment>(this.ENV.DSP_URL + "Consignment/GetConsignment",
                    {
                        consignmentID: consignmentID
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

            apportionateConsignmentALC(consignmentId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/ApportionateConsignmentALC", {
                    consignmentId: consignmentId
                });
            }

            //Change Status
            changeStatus(): ng.resource.IResourceClass<interfaces.consignment.IConsignment> {
                return this.$resource<interfaces.consignment.IConsignment>(this.ENV.DSP_URL + "Consignment/ChangeStatus", {
                }, {
                        save: {
                            method: 'POST'
                        }
                    });
            }

            changeALCStatus(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler>{
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/ChangeALCStatus", 
                {}, {
                    save: {
                        method: 'POST'
                    }
                });
            } 

            CreateFromSalesOrder(ownerEntityId: number, typeId: number, shipperId: number, consigneeId: number, supplierId: number, customerId: number, customNumber?: string, counterId?: number, customInvoiceNumber?: string, invoiceCounterId?: number, routeCodeId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/CreateFromSalesOrder", {
                    ownerEntityId: ownerEntityId,
                    typeId: typeId,
                    shipperId: shipperId,
                    consigneeId: consigneeId,
                    supplierId: supplierId,
                    customerId: customerId,
                    routeCodeId: routeCodeId,
                    customNumber: customNumber,
                    counterId: counterId,
                    customInvoiceNumber: customInvoiceNumber,
                    invoiceCounterId: invoiceCounterId

                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            CreateCommercialInvoiceFromSalesOrder(consignmentId: number, supplierId: number, customerId: number, customInvoiceNumber?: string, invoiceCounterId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/CreateCommercialInvoiceFromSalesOrder", {
                    consignmentId: consignmentId,
                    supplierId: supplierId,
                    customerId: customerId,
                    customInvoiceNumber: customInvoiceNumber,
                    invoiceCounterId: invoiceCounterId

                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            UpdateFromSalesOrder(consignmentId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/UpdateFromSalesOrder", {
                    consignmentId: consignmentId

                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }


            getConsignmentTypeDefaults(consignmentTypeId: number): ng.resource.IResourceClass<interfaces.consignment.ConsignmentTypeDefaults>
            {
                return this.$resource<interfaces.consignment.ConsignmentTypeDefaults>(this.ENV.DSP_URL + "Consignment/GetConsignmentTypeDefaults",
                    {
                        consignmentTypeId: consignmentTypeId
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );

            }

            GetConsignmentTypeDefaultsByConsignment(conId: number): ng.resource.IResourceClass<interfaces.consignment.ConsignmentTypeDefaults> {
                return this.$resource<interfaces.consignment.ConsignmentTypeDefaults>(this.ENV.DSP_URL + "Consignment/GetConsignmentTypeDefaultsByConsignment",
                    {
                        conId: conId
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );

            }

            GetConsignmentDefaults(consignmentId: number): ng.resource.IResourceClass<interfaces.consignment.ConsignmentDefaults> {
                return this.$resource<interfaces.consignment.ConsignmentDefaults>(this.ENV.DSP_URL + "Consignment/GetConsignmentDefaults",
                    {
                        consignmentId: consignmentId
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

            removeConsignmentFromManifest(conId: number, manId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/RemoveConsignmentFromManifest", {
                    conId: conId,
                    manId: manId
                });
            }

            getConsignmentForManifest(connectSearch: interfaces.applicationcore.ISearchObject, numberRecords: number, pageNumber: number, ownerEntityId: number, routeId: number, showAll: boolean, countryId?: number): ng.resource.IResourceClass<interfaces.consignment.IManifestConsignmentDisplay> {
                return this.$resource<interfaces.consignment.IManifestConsignmentDisplay>(this.ENV.DSP_URL + "Consignment/GetConsignmentForManifest", {
                    connectSearch: connectSearch,
                    numberRecords: numberRecords,
                    pageNumber: pageNumber,
                    ownerEntityId: ownerEntityId,
                    routeId: routeId,
                    showAll: showAll,
                    countryId: countryId
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            saveConsignmentForManifest(manId: number, selectedId: Array<number>): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/SaveConsignmentForManifest", {
                    manId: manId,
                    consignmentId: selectedId
                });
            }

            refreshConsignmentAddresses(conId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/RefreshConsignmentAddresses", {
                    conId: conId
                });
            }

            generateConsignmentComments(conId: number, comId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/GenerateConsignmentComments", {
                    consignmentId: conId,
                    commercialInvocieId: comId
                });
            }

            GetConsignmentSummary(): ng.resource.IResourceClass<interfaces.consignment.IConsignmentSummary> {
                return this.$resource<interfaces.consignment.IConsignmentSummary>(this.ENV.DSP_URL + "Consignment/GetConsignmentSummary", {
                    conId: '@conId',
                    comId: '@comId'
                });
            }

            CreateNewVersion(conId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/CreateNewVersion", {
                    id: conId
                });
            }

            allocateCertificateNumbers(conId: number, comId: number, comments?: string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/AllocateCertificateNumbers", {
                    conId: conId,
                    comId: comId,
                    comments: comments
                });
            }

            costExportConsignment(consignmentId: number, costModelId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/CostExportConsignment", {
                    consignmentId: consignmentId,
                    costModelId: costModelId
                });
            }


            reallocateCertificateNumbers(currentCertificateId: number, newCertificateNumber: string, commercialInvoiceId: number, cancellationReason: string, documentType: number, cancelAll: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/ReallocateCertificateNumbers", {
                    currentCertificateId: currentCertificateId,
                    newCertificateNumber: newCertificateNumber,
                    commercialInvoiceId: commercialInvoiceId,
                    cancellationReason: cancellationReason,
                    documentType: documentType,
                    cancelAll: cancelAll
                });
            }

            GetConsignmentCustomsSummary(conId: number): ng.resource.IResourceClass<interfaces.consignment.IConsignmentCustomsSummary> {
                return this.$resource<interfaces.consignment.IConsignmentCustomsSummary>(this.ENV.DSP_URL + "Consignment/GetConsignmentCustomsSummary", {
                    conId: conId
                }, {
                        query: {
                            method: "GET",
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            ViewDetailPR(): ng.resource.IResourceClass<interfaces.consignment.IConsignmentPRDisplay> {
                var viewDetail: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }

                return this.$resource<interfaces.consignment.IConsignmentPRDisplay>(this.ENV.DSP_URL + "Consignment/ViewDetailPR", {
                    conId: '@conId',
                    showOnlyProhibited: '@showOnlyProhibited',
                }, {
                        query: viewDetail
                    });
            }

            CheckForPR(conId: number, IsInbound: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/CheckForPR", {
                    conId: conId,
                    isInbound: IsInbound
                });
            }

            RunQuotaAllocation(conId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/RunQuotaAllocation", {
                    conId: conId,
                });
            }

            GetPermitsForDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Consignment/GetPermitsForDropdown", {
                    consignmentRestrictionId: '@consignmentRestrictionId',
                    searchText: '@searchText'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            Create52203DDClaim(consignmentId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/Create52203DDClaim", {
                    consignmentId: consignmentId,
                });
            }

            GetConsignmentMetricsData(Year: number,Month:number): ng.resource.IResourceClass<interfaces.consignment.IConsignmentMetricsData>
            {
                return this.$resource<interfaces.consignment.IConsignmentMetricsData>(this.ENV.DSP_URL + "Consignment/GetConsignmentMetricsData",
                    {
                        Year: Year,
                        Month: Month
                    }, {
                        get: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

            GetConsignmentSLAMetricsData(): ng.resource.IResourceClass<interfaces.consignment.IConsignmentSLAMetricsData> {
                return this.$resource<interfaces.consignment.IConsignmentSLAMetricsData>(this.ENV.DSP_URL + "Consignment/GetConsignmentSLAMetricsData",{                       
                    }, {
                    get: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                }
                );
            }


            GetImportConsignmentSLAMetricsData(): ng.resource.IResourceClass<interfaces.consignment.IConsignmentSLAMetricsData> {
                return this.$resource<interfaces.consignment.IConsignmentSLAMetricsData>(this.ENV.DSP_URL + "Consignment/GetImportConsignmentSLAMetricsData",{                       
                    }, {
                    get: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                }
                );
            }            

            GetConsignmentComments(consignmentId: number): ng.resource.IResourceClass<interfaces.consignment.IConsignmentComments> {
                return this.$resource<interfaces.consignment.IConsignmentComments>(this.ENV.DSP_URL + "Consignment/GetConsignmentComments",
                    {
                        consignmentId: consignmentId
                    }, {
                        get: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

            //UCR Type:
            //1 = Single Use Consignment UCR
            //2 = Multiple Use Consignment UCR
            //3 = Single Use Invoice UCR
            //4 = Multiple Use Invoice UCR
            GenerateUCR(consignmentId: number, ucrType: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/GenerateUCR", {
                    consignmentId: consignmentId,
                    ucrType: ucrType
                });
            }

            GetExportConsignmentXML(consignmentNumber: string,conId: number, EmbedDocuments: boolean) {


                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: this.ENV.DSP_URL + 'Consignment/GetExportConsignmentXML?consignmentNumber=' + consignmentNumber+'&conId='+conId+'&EmbedDocuments='+EmbedDocuments
                }).then((response) => {
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });
                    var filename = response.headers()['x-filename'] || 'download.bin';

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }


            GetPOWizardDetailExcel(consignmentNumber: string,conId: number) {


                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: this.ENV.DSP_URL + 'Consignment/GetPOWizardDetailExcel?consignmentNumber=' + consignmentNumber+'&conId='+conId
                }).then((response) => {
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });
                    var filename = response.headers()['x-filename'] || 'download.bin';

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }      
            
            UploadPOWizardFromExcel(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/UploadPOWizardFromExcel", {});
            }

           

        }
    }
    angular.module("app").service("consignmentService", services.consignment.consignmentService);
}